var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getSafeEventHandlerOpts } from '../utils/touchEvent';
var getAngleFromDelta = function (deltaX, deltaY) { return Math.atan2(deltaY, deltaX) * (180 / Math.PI); };
var isLeftMouseButton = function (event) { return event.buttons === 1; };
/**
 * getPosition returns a position element that works for mouse or touch events
 *
 * @param  {[Event]} event [the received event]
 * @return {[Object]}      [x and y coords]
 */
var getPosition = function (event) {
    if ('touches' in event) {
        var _a = event.touches[0] || {}, _b = _a.pageX, pageX = _b === void 0 ? 0 : _b, _c = _a.pageY, pageY = _c === void 0 ? 0 : _c;
        return { x: pageX, y: pageY };
    }
    var screenX = event.screenX, screenY = event.screenY;
    return { x: screenX, y: screenY };
};
// eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
var noop = function () { };
/**
 * The code for this component was inspired in react-easy-swipe
 * https://github.com/leandrowd/react-easy-swipe
 */
// eslint-disable-next-line max-lines-per-function
var SwipeListener = function (_a) {
    var children = _a.children, _b = _a.onSwipeStart, onSwipeStart = _b === void 0 ? noop : _b, _c = _a.onSwipeMove, onSwipeMove = _c === void 0 ? noop : _c, _d = _a.onSwipeEnd, onSwipeEnd = _d === void 0 ? noop : _d, _e = _a.onSwipeUp, onSwipeUp = _e === void 0 ? noop : _e, _f = _a.onSwipeDown, onSwipeDown = _f === void 0 ? noop : _f, _g = _a.onSwipeLeft, onSwipeLeft = _g === void 0 ? noop : _g, _h = _a.onSwipeRight, onSwipeRight = _h === void 0 ? noop : _h, _j = _a.trackMouse, trackMouse = _j === void 0 ? false : _j;
    var _k = __read(useState(null), 2), swiperRef = _k[0], setSwiperRef = _k[1];
    var moveStart = useRef();
    var movePosition = useRef();
    var moving = useRef();
    var onSwipeEndRef = useRef();
    onSwipeEndRef.current = onSwipeEnd;
    var onSwipeMoveRef = useRef();
    onSwipeMoveRef.current = onSwipeMove;
    useEffect(function () {
        if (swiperRef) {
            swiperRef.addEventListener('touchmove', handleSwipeMove, getSafeEventHandlerOpts({
                capture: true,
                passive: false,
            }));
        }
        return function () {
            if (swiperRef) {
                swiperRef.removeEventListener('touchmove', handleSwipeMove, getSafeEventHandlerOpts({
                    capture: true,
                    passive: false,
                }));
            }
        };
    }, [swiperRef]);
    var handleMouseDown = useCallback(function (event) {
        if (!isLeftMouseButton(event)) {
            return;
        }
        // setup mouse listeners on document to track swipe since swipe can leave container
        document.addEventListener('mousemove', handleSwipeMove);
        document.addEventListener('mouseup', handleMouseUp);
        handleSwipeStart(event);
    }, [onSwipeStart, onSwipeUp, onSwipeRight, onSwipeDown, onSwipeLeft]);
    var handleMouseUp = useCallback(function (event) {
        document.removeEventListener('mousemove', handleSwipeMove);
        document.removeEventListener('mouseup', handleMouseUp);
        handleSwipeEnd(event);
    }, [onSwipeUp, onSwipeRight, onSwipeDown, onSwipeLeft]);
    var handleSwipeStart = useCallback(function (event) {
        var _a = getPosition(event), x = _a.x, y = _a.y;
        moveStart.current = { x: x, y: y };
        onSwipeStart(event);
    }, [onSwipeStart]);
    var handleSwipeMove = useCallback(function (event) {
        if (!moveStart.current) {
            return;
        }
        var _a = getPosition(event), x = _a.x, y = _a.y;
        var deltaX = x - moveStart.current.x;
        var deltaY = y - moveStart.current.y;
        var deltaPosition = { x: deltaX, y: deltaY };
        moving.current = true;
        // handling the responsibility of cancelling the scroll to
        // the component handling the event
        var shouldPreventDefault = onSwipeMoveRef.current(deltaPosition, event);
        if (shouldPreventDefault) {
            event.preventDefault();
        }
        movePosition.current = deltaPosition;
    }, []);
    var handleSwipeEnd = useCallback(
    // eslint-disable-next-line complexity
    function (event) {
        onSwipeEndRef.current(event);
        if (moving.current && movePosition.current) {
            var angle = getAngleFromDelta(movePosition.current.x, movePosition.current.y);
            if (angle >= -112.5 && angle < -67.5) {
                onSwipeUp(event);
            }
            if (angle >= -22.5 && angle < 22.5) {
                onSwipeRight(event);
            }
            if (angle >= 67.5 && angle < 112.5) {
                onSwipeDown(event);
            }
            if ((angle >= 157.5 && angle <= 180) || (angle >= -180 && angle <= -157.5)) {
                onSwipeLeft(event);
            }
        }
        moving.current = null;
        movePosition.current = null;
    }, [onSwipeUp, onSwipeRight, onSwipeDown, onSwipeLeft]);
    var onMouseDown = trackMouse ? handleMouseDown : noop;
    return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    React.createElement("div", { ref: setSwiperRef, onTouchStart: handleSwipeStart, onTouchEnd: handleSwipeEnd, onMouseDown: onMouseDown, "data-testid": "swipe-listener" }, children));
};
export default SwipeListener;
