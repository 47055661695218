var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { height, overflow } from 'styled-system';
import { Flex } from '../../common/components/Flex';
var Container = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), height, overflow);
Container.defaultProps = {
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
};
export default Container;
var templateObject_1;
