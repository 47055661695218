var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { color, display, alignItems, position, space, backgroundColor, boxShadow, width, maxHeight, flex, size, zIndex, borderRadius, } from 'styled-system';
import { Button } from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import { Text } from '../../common/components/Text';
import { DRAWER_CLOSE_BUTTON_Z_INDEX } from '../../common/constants/z-index';
import { useLocalization } from '../../common/providers/LocaleProvider';
import { textWithEllipsis } from '../../common/theme/helper';
import { boxSizing, cursor, themeHeight, themeRight, themeWidth, } from '../../common/theme/system-utilities';
var ICON_SIZE = 16;
var HeaderSection = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), alignItems, backgroundColor, boxShadow, boxSizing, color, display, space, themeHeight, themeWidth, width, zIndex);
var headerSectionStyles = {
    alignItems: 'center',
    backgroundColor: 'sys.neutral.background.default',
    boxShadow: 'bottom',
    boxSizing: 'border-box',
    color: 'sys.neutral.text.strong',
    display: 'flex',
    px: 'sp_20',
    py: 'sp_0',
    themeHeight: ['54px', 'sp_64'],
    themeWidth: '100%',
    width: 1,
    zIndex: DRAWER_CLOSE_BUTTON_Z_INDEX,
};
var CloseIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), size, space, cursor);
CloseIcon.defaultProps = {
    size: ICON_SIZE,
};
var CloseButton = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), cursor, position, themeRight, borderRadius);
var closeButtonStyles = {
    cursor: 'pointer',
    kind: 'bare',
    position: 'absolute',
    themeRight: 'sp_16',
    p: 'sp_8',
    borderRadius: 'circle',
};
var Title = styled(Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), maxHeight, flex, textWithEllipsis());
Title.defaultProps = {
    fontSize: ['fs_16', null, null, 'fs_18'],
    lineHeight: ['18px', null, null, 'lh_20'],
    fontWeight: 'bold',
    textAlign: 'center',
    mr: 'sp_32',
    flex: 1,
    as: 'h1',
};
var Header = function (_a) {
    var onClick = _a.onClick, title = _a.title, id = _a.id;
    var t = useLocalization().t;
    var text = title === undefined ? t('h24_important_info') : title;
    return (React.createElement(HeaderSection, __assign({}, headerSectionStyles),
        React.createElement(CloseButton, __assign({}, closeButtonStyles, { "data-testid": "close-button", onClick: onClick, "aria-label": t('h24_close'), autoFocus: false }),
            React.createElement(CloseIcon, { name: "closeSmall" })),
        React.createElement(Title, __assign({ "data-testid": "drawer-header-title" }, (id && { id: id })), text)));
};
export default Header;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
