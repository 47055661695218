var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
import EventBus from '../event-bus/EventBus';
var eventBus = new EventBus();
export var useSubscription = function (event, options) {
    if (options === void 0) { options = {}; }
    var _a = __read(useState(), 2), state = _a[0], setState = _a[1];
    useEffect(function () {
        setState(null);
        eventBus.on(event, setState);
        if (options.latestEmitted) {
            var latestEmitted = eventBus.latestEmitted(event);
            if (latestEmitted) {
                setState(latestEmitted);
            }
        }
        return function () { return eventBus.off(event, setState); };
    }, [event]);
    return [state];
};
export var useSubscriptionCallback = function (event, callback, options) {
    if (options === void 0) { options = {}; }
    useEffect(function () {
        eventBus.on(event, callback);
        if (options.latestEmitted) {
            var latestEmitted = eventBus.latestEmitted(event);
            if (latestEmitted) {
                callback(latestEmitted);
            }
        }
        return function () { return eventBus.off(event, callback); };
    }, [event]);
};
