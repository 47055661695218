var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { space, fontSize, height } from 'styled-system';
import RichTextBlock from '../../common/components/RichText';
import { boxSizing, overflowX, overflowY, } from '../../common/theme/system-utilities';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, height, boxSizing, overflowX, overflowY);
Container.defaultProps = {
    height: '100%',
    boxSizing: 'border-box',
    overflowY: 'auto',
    overflowX: 'hidden',
};
var StyledRichTextBlock = styled(RichTextBlock)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  p {\n    ", ";\n  }\n"], ["\n  ", ";\n  p {\n    ", ";\n  }\n"])), space, fontSize);
StyledRichTextBlock.defaultProps = {
    p: ['sp_16', 'sp_24', null, 'sp_32'],
    fontSize: ['fs_14', null, 'fs_16', 'fs_18'],
};
var Content = function (_a) {
    var html = _a.html, content = _a.content, p = _a.p;
    return (React.createElement(Container, null, content || (React.createElement(StyledRichTextBlock, { p: p, dangerouslySetInnerHTML: { __html: html }, "data-testid": "html" }))));
};
export default Content;
var templateObject_1, templateObject_2;
